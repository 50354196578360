var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "nav-item nav-dropdown relative",
    attrs: {
      "tag": "li",
      "to": _vm.url,
      "disabled": ""
    }
  }, [_c('div', {
    staticClass: "ml-4 nav-link-custom cursor-pointer nav-dropdown-toggle py-2 flex items-center justify-between",
    on: {
      "click": _vm.handleClick
    }
  }, [_c('div', {
    staticClass: "flex items-center space-x-2"
  }, [_c('i', {
    staticClass: "text-gray-500",
    class: _vm.icon
  }), _c('span', {
    staticClass: "text-base text-gray-500"
  }, [_vm._v(_vm._s(_vm.name))])]), _c('i', {
    staticClass: "fa-solid fa-angle-down cursor-pointer relative right-3 transition-transform duration-200 text-gray-500",
    class: _vm.isOpen ? 'transform rotate-180' : ''
  })]), _c('ul', {
    ref: "dropdownContent",
    staticClass: "nav-dropdown-items overflow-hidden transition-all text-base duration-300 ease-in-out",
    style: _vm.isOpen ? {
      height: _vm.calculatedHeight + 'px'
    } : {
      height: '0px'
    }
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }