<template>
  <div v-if="isExternalLink">
    <a :href="url"  :class="`${classList}`">
      <i :class="icon"></i> {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </a>
  </div>
  <div v-else>
    <div v-if="this.url==='/customers/list'">
      <router-link :to="url" exact :class="`text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 ${classList}`">
      <i class="mr-1" :class="icon"></i>
       {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
    </div>
    <div v-else-if="this.url==='/customers/banned'">
      <router-link :to="url" exact :class="`text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 ${classList}`">
        <i class="mr-1" :class="icon"></i>
       {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
    </div>
    <div v-else-if="this.url==='/customers/user-renewal'">
      <router-link :to="url" exact :class="`text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 ${classList}`">
        <i class="mr-1" :class="icon"></i>
       {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
    </div>
    <div v-else-if="this.url==='/customers/reviews'">
      <router-link :to="url" exact :class="`text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 ${classList}`">
        <i class="mr-1" :class="icon"></i>
       {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
    </div>
    <!-- || this.url==='customers/banned' || this.url==='customers/user-renewal' || this.url==='customers/reviews' -->
    <router-link v-else :to="url" exact :class="`text-gray-500 py-2 px-4 ml-4 block hover:no-underline hover:bg-gray-100 ${classList}`">
       {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "sidebar-nav-link",
  props: {
    name: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    badge: {
      type: Object,
      default: () => {}
    },
    variant: {
      type: String,
      default: ""
    },
    classes: {
      type: String,
      default: ""
    }
  },
  computed: {
    classList() {
      return ["nav-link", this.linkVariant, ...this.itemClasses];
    },
    linkVariant() {
      return this.variant ? `nav-link-${this.variant}` : "";
    },
    itemClasses() {
      return this.classes ? this.classes.split(" ") : [];
    },
    isExternalLink() {
      if (this.url.substring(0, 4) === "http") {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
