var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "sidebar-minimizer",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.sidebarMinimize();
        _vm.brandMinimize();
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }