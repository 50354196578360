var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SidebarHeader'), _c('SidebarForm'), _c('nav', {
    staticClass: "w-72 fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full sm:translate-x-0",
    attrs: {
      "id": "sidebar-multi-level-sidebar",
      "aria-label": "Sidebar"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }), _c('div', {
    staticClass: "h-full py-4 overflow-y-scroll bg-gray-50 dark:bg-gray-800"
  }, [_c('ul', {
    staticClass: "font-medium px-2 relative top-10"
  }, [_vm._l(_vm.navItems, function (item, index) {
    return [item.title ? [_c('SidebarNavTitle', {
      key: index,
      attrs: {
        "name": item.name,
        "classes": item.class,
        "wrapper": item.wrapper
      }
    })] : item.divider ? [_c('SidebarNavDivider', {
      key: item.class,
      attrs: {
        "classes": item.class
      }
    })] : item.label ? [_c('SidebarNavLabel', {
      key: index,
      attrs: {
        "name": item.name,
        "url": item.url,
        "icon": item.icon,
        "label": item.label,
        "classes": item.class
      }
    })] : [item.children ? [_c('SidebarNavDropdown', {
      key: index,
      attrs: {
        "name": item.name,
        "url": item.url,
        "icon": item.icon
      }
    }, [_vm._l(item.children, function (childL1, index) {
      return [childL1.children ? [_c('SidebarNavDropdown', {
        key: index,
        attrs: {
          "name": childL1.name,
          "url": childL1.url,
          "icon": childL1.icon
        }
      }, _vm._l(childL1.children, function (childL2, index) {
        return _c('li', {
          key: index,
          staticClass: "nav-item"
        }, [_c('SidebarNavLink', {
          key: index,
          attrs: {
            "name": childL2.name,
            "url": childL2.url,
            "icon": childL2.icon,
            "badge": childL2.badge,
            "variant": item.variant
          }
        })], 1);
      }), 0)] : [_c('SidebarNavItem', {
        key: index,
        attrs: {
          "classes": item.class
        }
      }, [_c('SidebarNavLink', {
        attrs: {
          "name": childL1.name,
          "url": childL1.url,
          "icon": childL1.icon,
          "badge": childL1.badge,
          "variant": item.variant
        }
      })], 1)]];
    })], 2)] : [_c('SidebarNavItem', {
      key: index,
      attrs: {
        "classes": item.class
      }
    }, [_c('SidebarNavLink', {
      attrs: {
        "name": item.name,
        "url": item.url,
        "icon": item.icon,
        "badge": item.badge,
        "variant": item.variant
      }
    })], 1)]]];
  }), _vm._t("default")], 2), _c('SidebarFooter'), _c('SidebarMinimizer')], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }