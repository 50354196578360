var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "app-footer text-center"
  }, [_c('span', [_c('a', {
    attrs: {
      "href": "https://edutore.com"
    }
  }, [_vm._v(" CMS Ebooks ")]), _vm._v(" © " + _vm._s(_vm.year) + " By Gramedia ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }