var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "text-small bg-red-50 hidden",
    class: _vm.classList
  }, [_vm.wrapper && _vm.wrapper.element ? [_c(_vm.wrapper.element, _vm._b({
    tag: "component",
    staticClass: "text-sm"
  }, 'component', _vm.wrapper.attributes, false), [_vm._v(" " + _vm._s(_vm.name) + " ")])] : [_vm._v(" " + _vm._s(_vm.name) + " ")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }