var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isExternalLink ? _c('div', [_c('a', {
    class: ("" + (_vm.classList)),
    attrs: {
      "href": _vm.url
    }
  }, [_c('i', {
    class: _vm.icon
  }), _vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1)]) : _c('div', [this.url === '/customers/list' ? _c('div', [_c('router-link', {
    class: ("text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 " + (_vm.classList)),
    attrs: {
      "to": _vm.url,
      "exact": ""
    }
  }, [_c('i', {
    staticClass: "mr-1",
    class: _vm.icon
  }), _vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1)], 1) : this.url === '/customers/banned' ? _c('div', [_c('router-link', {
    class: ("text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 " + (_vm.classList)),
    attrs: {
      "to": _vm.url,
      "exact": ""
    }
  }, [_c('i', {
    staticClass: "mr-1",
    class: _vm.icon
  }), _vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1)], 1) : this.url === '/customers/user-renewal' ? _c('div', [_c('router-link', {
    class: ("text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 " + (_vm.classList)),
    attrs: {
      "to": _vm.url,
      "exact": ""
    }
  }, [_c('i', {
    staticClass: "mr-1",
    class: _vm.icon
  }), _vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1)], 1) : this.url === '/customers/reviews' ? _c('div', [_c('router-link', {
    class: ("text-gray-500 py-2 px-4 block hover:no-underline hover:bg-gray-100 " + (_vm.classList)),
    attrs: {
      "to": _vm.url,
      "exact": ""
    }
  }, [_c('i', {
    staticClass: "mr-1",
    class: _vm.icon
  }), _vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1)], 1) : _c('router-link', {
    class: ("text-gray-500 py-2 px-4 ml-4 block hover:no-underline hover:bg-gray-100 " + (_vm.classList)),
    attrs: {
      "to": _vm.url,
      "exact": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " "), _vm.badge && _vm.badge.text ? _c('b-badge', {
    attrs: {
      "variant": _vm.badge.variant
    }
  }, [_vm._v(_vm._s(_vm.badge.text))]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }