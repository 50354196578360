<template>
  <router-link tag="li" class="nav-item nav-dropdown relative"  :to="url" disabled>
    <div class=" ml-4 nav-link-custom cursor-pointer nav-dropdown-toggle py-2 flex items-center justify-between" @click="handleClick">
      <div class="flex items-center space-x-2 ">
        <i class="text-gray-500" :class="icon"></i> 
        <span class="text-base text-gray-500">{{ name }}</span>
      </div>
      <i  class="fa-solid fa-angle-down cursor-pointer relative right-3 transition-transform duration-200 text-gray-500" :class="isOpen ? 'transform rotate-180' : ''"></i>
    </div>
    <ul  ref="dropdownContent" class="nav-dropdown-items overflow-hidden transition-all text-base duration-300 ease-in-out" :style="isOpen ? { height: calculatedHeight + 'px' } : { height: '0px' }">
      <slot></slot>
    </ul>
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    url: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isOpen: false,
      calculatedHeight: 0,
    };
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle("open");
      this.isOpen = !this.isOpen;
      this.$nextTick(() => {
        this.calculatedHeight = this.$refs.dropdownContent.scrollHeight;
      });
    }
  }
};
</script>
<style>
.dropdown-enter-active, .dropdown-leave-active {
  transition: height 0.3s ease-in-out;
}
.dropdown-enter, .dropdown-leave-to {
  height: 0;
}</style>